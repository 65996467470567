import React, { useEffect, useState } from 'react'
import "./aboutUs.css"

function AboutUs() {
  const [text, setText] = useState('');
  const originalText = "At Local Movers, we're your steadfast partners in transitions. From moves to furniture assembly and removals, we handle it all with precision and care. Our dedicated team crafts seamless experiences, ensuring your journey is stress-free. With us, expect more than services—we offer personalized solutions, striving for your satisfaction every step of the way. Experience reliability and efficiency with Local Movers, your trusted companion in life's ventures.";

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      setText(originalText.substring(0, currentIndex));
      currentIndex++;
      if (currentIndex > originalText.length) {
        clearInterval(interval);
      }
    }, 50); // Adjust the speed of typing (milliseconds per character)
    
    return () => clearInterval(interval); 
  }, [originalText]);

  return ( 
    <section id="About-Us">


    <div className="about-us-container">
            <div className="title-container">
             <center> <h1 id="About-Us">About Us</h1></center>
            </div>
            <div className="text-container">
              <p className='About-Us-Text'>{text}</p>
            </div>
          </div>
          </section>
    
      )
    
}

export default AboutUs
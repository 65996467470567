import React, { useRef,useState } from 'react';
import emailjs from '@emailjs/browser';
import "./contact.css"
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


function Contact() {
  const form = useRef();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zapbe8d', 'template_kk5isaq', form.current, 'BeGkhvlaD0-zU6QFY')
      .then((result) => {
    e.target.reset()
    setShowSuccessAlert(true);
    setTimeout(() => {
    setShowSuccessAlert(false);
}, 6000);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    
    <section className='contact'id="Contact" >

    <div className='content' >
      <h2>Contact Us</h2>

    </div>
    {showSuccessAlert && (
            <Alert
              severity='success'
              onClose={() => setShowSuccessAlert(false)}
            >
              <AlertTitle>Success</AlertTitle>
              Great job! Your message has been sent successfully.
            </Alert>
          )} 
    <div className='container'>
      <div className='contactInfo'>
        <div className='box'>
          <div className='icon'></div>
            <div className='text'>
              <h3>Address</h3>
              <p className='Adresse-localMovers-San Bruno'>401 Piccadilly Pl<br/>
                San Bruno, CA  94066<br/>
                United States
              </p>
            </div>
        </div>
        <div className='box'>
          <div className='icon'></div>
            <div className='text'>
              <h3>Phone</h3>
              <p>+1 4154206078</p>
            </div>
        </div>

        <div className='box'>
          <div className='icon'></div>
            <div className='text'>
              <h3>Email</h3>
              <p>info@localmoversf.com</p>
            </div>
        </div>
      </div>
      <div className='ContactForm'>
        <form  ref={form} onSubmit={sendEmail}>
          <h2>Send Message</h2>
          <div className='inputBox'>
            <input type="text" name="Full_Name" required="required">
              
            </input>
            <span>Full Name</span>
          </div>

          <div className='inputBox'>
            <input type="text" name="Email" required="required">
            </input>
            <span>Email</span>
          </div>
          <div className='inputBox'>
            <textarea required="required" name="Message">
            </textarea>
              <span>Type your Message...</span>
            </div>
            <div className='inputBox'>
            <input type="submit" name="" value="Send">
            </input>
            </div>

        </form>     
                  
            
      </div>
    </div>

    </section>
  )
}

export default Contact
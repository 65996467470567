import React, { useState } from 'react'
import "./Navbar.css"
import {MenuMovers} from "./MenuMovers"
import NavbarLogo from "../../Images movers/NavbarLogo.png"


function Navbar() {
   const [clicked,setclicked]=useState(false)
   const handelClick=()=>{
    setclicked(!clicked)
    
   }
  return (
    <nav className='Movers-Itmes'>
      <img className='Movers-logo' alt="Movers-Logo" id="Home" src={NavbarLogo}/>
     <div className='Movers-menuI' >
      <i onClick={handelClick}className={clicked ?"fas fa-times":"fas fa-bars"}></i>
     </div>

      <ul className={clicked?"Movers-menu active":"Movers-menu"}> 
        {MenuMovers.map((item, index)=>{
          return(
            <li key={index}>
            <a className={item.cName} href={item.href}>
              {item.tile}
            </a>

          </li>
          )
        })}

      </ul>
    </nav>
  )
}

export default Navbar
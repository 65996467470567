import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControlLabel, FormControl, FormLabel, Radio, RadioGroup } from '@mui/material';
import emailjs from '@emailjs/browser';


export default function MovingPopUp(props) {

  const { open } = props;
  const [step, setStep] = useState(1);

  function prevStep() {
    setStep(step - 1);
  }

  function nextStep() {
    setStep(step + 1);
  }
  const [formData, setFormData] = useState({
    "Junk-Removal-Type": "",
    "Junk-Removal-Amount": "",
    "Junk-Removal-Stairs": "",



    "full-name": "",
    "phone-number": "",
    "gmail-address": "",

    "Move-Date": "",
    "Move-Size": "",
    "Loading-Setup": "",
    "Unloading-Setup": "",
    "large-furniture-items": "",
    "load-items": "",
    "unload-items": "",
    "Move-Distance":[],
    "additional_packing":""
  });
  const handleInputChange = (field, event) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const isLastStep = step === 7;
  const isFirstStep = step === 1;


  const sendEmail = (e) => {
    e.preventDefault();


    const emailData = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      emailData.append(key, value);
    });
  
    const hiddenForm = document.createElement('form');
    hiddenForm.style.display = 'none';
  
    Object.entries(formData).forEach(([key, value]) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value;
      hiddenForm.appendChild(input);
    });
  
    document.body.appendChild(hiddenForm);
    emailjs.sendForm('service_zapbe8d', 'template_sbm04bo', hiddenForm, 'BeGkhvlaD0-zU6QFY')
      .then((result) => {
        setFormData({
          "full-name": "",
          "phone-number": "",
          "gmail-address": "",
      
          "Move-Date": "",
          "Move-Size": "",
          "Loading-Setup": "",
          "Unloading-Setup": "",
          "additional_packing":""
        });
        } )
           .catch((error) => {
      console.log(error.text);
    })
    .finally(() => {
      setStep(1);
    });
    props.cancel(); 

   };


  return (
    <React.Fragment>
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle>Join Us Now!</DialogTitle>
        <DialogContent>
        {step === 1 && (
              <>
                <TextField
                  label="Full Name"
                  fullWidth
                  margin="normal"
                  value={formData["full-name"]}
                  onChange={(event) => handleInputChange("full-name", event)}
                />
                <TextField
                  label="Phone Number"
                  fullWidth
                  margin="normal"
                  value={formData["phone-number"]}
                  onChange={(event) => handleInputChange("phone-number", event)}
                />
                <TextField
                  label="Email Adress"
                  fullWidth
                  margin="normal"
                  value={formData["gmail-address"]}
                  onChange={(event) => handleInputChange("gmail-address", event)}
                />
              </>
            )}
          {step === 2 && (
            <>
            <p>what is your preferrd move date ?</p>
              <TextField
                autoFocus
                margin="dense"
                id="Move-Date"
                label="dd/mm/yyyy"
                type="date"
                fullWidth
                variant="standard"
                value={formData["Move-Date"]}
                onChange={(event) => handleInputChange("Move-Date", event)}

              />

            </>
          )}

          {step === 3 && (
            <>
            
            <FormControl>
            <FormLabel id="Full-Servecis-Moving">What is the size of your move ?</FormLabel>
            <RadioGroup
             aria-labelledby="Full-Servecis-Moving"
             name="Move-Size"
             value={formData["Move-Size"]}
             onChange={(event) => handleInputChange("Move-Size", event)}

            >
             <FormControlLabel value="Studio" control={<Radio />} label="Studio" />
             <FormControlLabel value="1 bedroom home" control={<Radio />} label="1 bedroom home" />
             <FormControlLabel value="2 bedroom home" control={<Radio />} label="2 bedroom home" />
             <FormControlLabel value="3 bedroom home" control={<Radio />} label="3 bedroom home" />
             <FormControlLabel value="4 bedroom home" control={<Radio />} label="4 bedroom home" />
             <FormControlLabel value="5 bedroom home" control={<Radio />} label="5 bedroom home" />
             <FormControlLabel value="Just a few things" control={<Radio />} label="Just a few things" />
             </RadioGroup>
            </FormControl>
            </>
          )}

          {step === 4 && (
            <>
            <FormControl>
            <FormLabel id="Full-Servecis-Moving">What's the loading setup at the start location?</FormLabel>
            <RadioGroup
             aria-labelledby="Full-Servecis-Moving"
             name="Loading-Setup"
             value={formData["Loading-Setup"]}
             onChange={(event) => handleInputChange("Loading-Setup", event)}

            >
             <FormControlLabel value="load via stairs" control={<Radio />} label="load via stairs" />
             <FormControlLabel value="load via passenger elevator" control={<Radio />} label="load via passenger elevator" />
             <FormControlLabel value="load via freight elevator" control={<Radio />} label="load via freight elevator" />
             <FormControlLabel value="load flat; no stairs or elevator" control={<Radio />} label="load flat; no stairs or elevator" />
             </RadioGroup>
            </FormControl>
            </>
          )}


            {step === 5 && (
            <>
            <FormControl>
            <FormLabel id="Full-Servecis-Moving">How about unloading when you get there?</FormLabel>
            <RadioGroup
             aria-labelledby="Full-Servecis-Moving"
             name="Unloading-Setup"
             value={formData["Unloading-Setup"]}
             onChange={(event) => handleInputChange("Unloading-Setup", event)}
       >
             <FormControlLabel value="Unload via stairs" control={<Radio />} label="Unload via stairs" />
             <FormControlLabel value="Unload via passenger elevator" control={<Radio />} label="Unload via passenger elevator" />
             <FormControlLabel value="Unload via freight elevator" control={<Radio />} label="Unload via freight elevator" />
             <FormControlLabel value="Unload flat; no stairs or elevator" control={<Radio />} label="Unload flat; no stairs or elevator" />
             </RadioGroup>
            </FormControl>
            </>
          )}
            {step === 6 && (
            <>
            <FormControl>
            <FormLabel id="Full-Servecis-Moving">Do you need an additional packing service for your move ?</FormLabel>
            <RadioGroup
             aria-labelledby="Additional packing"
             name="additional_packing"
             value={formData["additional_packing"]}
             onChange={(event) => handleInputChange("additional_packing", event)}
       >
             <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
             <FormControlLabel value="No" control={<Radio />} label="No" />
             </RadioGroup>
            </FormControl>
            </>
          )}
            {step === 7 && (
             <>
              <DialogContentText>Confirmation</DialogContentText>
              <p>
                <strong>Full Name:</strong> {formData["full-name"]}
              </p>
              <p>
                <strong>Phone Number:</strong> {formData["phone-number"]}
              </p>
              <p>
                <strong>Email Address:</strong> {formData["gmail-address"]}
              </p>
              <p>
                <strong>Move Date:</strong> {formData["Move-Date"]}
              </p>
              <p>
                <strong>Move Size:</strong> {formData["Move-Size"]}
              </p>
              <p>
                <strong>Loading Setup:</strong> {formData["Loading-Setup"]}
              </p>
              <p>
                <strong>Unloading Setup:</strong> {formData["Unloading-Setup"]}
              </p>
              <p>
                <strong>Additional packing:</strong> {formData["additional_packing"]}
              </p>
          </>
          )}
        </DialogContent>
        <DialogActions>
         <center>
         {isFirstStep ?          
                  <Button onClick={() => {props.cancel(); setStep(1)}}>
                  Cancel
                </Button>
                :
                <Button onClick={prevStep}>
                  Back
               </Button>
                  }

              {isLastStep ?          
              <Button variant="contained" onClick={sendEmail}>Submit</Button>
                :
                <Button onClick={nextStep}>
                Next
              </Button>
                  }
         
          </center> 
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import React from 'react'
import "./header.css"

function Header() {
  /*      <img alt="USA-Movers" src={picone}></img>
*/
  return (
    <div className='MoversPicture' >
      <div className='Slogan'>
    <h1 id="Home">
       Local Movers
    </h1>
    <p>
         From here to there , we're every where !
    </p>
      </div>
    </div>
  )
}

export default Header
export const MenuMovers =[
    {
        tile : "Home",
        href : "/",
        cName : "nav-links"

    },
    {
        tile : "Our Services",
        href : "#Our-Services",
        cName : "nav-links"

    },
    {
        tile : "About Us",
        href : "#About-Us",
        cName : "nav-links"

    },
    {
        tile : "Feedback",
        href : "#Feedback",
        cName : "nav-links"
    },
    {
        tile : "Contact",
        href : "#Contact",
        cName : "nav-links"
        
    },

]
import React from 'react'
import "./Review.css"
import Brayn from "../../Images movers/BraynLocalMovers.jpg"
import Kristen from "../../Images movers/KristenLocalMovers.png"
import Angela from "../../Images movers/AngelaLocalMovers.png"


function Review() {
  return (
    <div className='BodyDiv'id="Feedback">
   
    <div className='Review-Container'>
        <div className='box-review' > 
            <i className='fas fa-quote-left quote'></i>
                <p>Movers were great! They were very hard working. Very quick despite having to move from my third floor apartment to a house. I had a lot of things and they managed to move everything quickly.
                     It was great having wardrobe boxes. They also wrapped the furniture well.
                </p>
                <div className='content'>
                    <div className='info'>
                        <div className='fullName'>Bryan C</div>
                        <div className='location'>Emeryville, CA</div>
                        <div className='starts'>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                        </div>
                    </div>
                    <div className='image'> 
                        <img src={Brayn} alt='Our-Client'></img>
                    </div>
                </div>
        </div>
        <div className='box-review'> 
            <i className='fas fa-quote-left quote'></i>
                <p>These guys were great! They texted us when they were on our way, 
                    they understand exactly what we wanted and were efficient and careful with our stuff. I highly recommend Local movers and our guys were Will.<br/> Thank you!




                </p>
                <div className='content'>
                    <div className='info'>
                        <div className='fullName'>Angela M</div>
                        <div className='location'>San Francisco, CA</div>
                        <div className='starts'>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                        </div>
                    </div>
                    <div className='image'> 
                        <img src={Angela} alt='Our-Client'></img>
                    </div>
                </div>
        </div>
        <div className='box-review'> 
            <i className='fas fa-quote-left quote'></i>
                <p>AMAZING WORK!!! Started right away and got done faster than expected. Moved everything out and back in with no issues.<br/>
                     Very respectful and well worth the LOW price! Will definitely book again in the future!
                </p>
                <div className='content'>
                    <div className='info'>
                        <div className='fullName'>Kristen C</div>
                        <div className='location'>Sunnyvale, CA</div>
                        <div className='starts'>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='far fa-star'></i>
                        </div>
                    </div>
                    <div className='image'> 
                        <img src={Kristen} alt='Our-Client'></img>
                    </div>
                </div>
        </div>
    </div>
    <div/>
    </div>
  )
}

export default Review
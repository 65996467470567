import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField,FormControlLabel, FormControl, FormLabel, Radio, RadioGroup } from '@mui/material';
import emailjs from '@emailjs/browser';

export default function JunkRemovalPopup(props) {
  const [formData, setFormData] = useState({
    "full-name": "",
    "phone-number": "",
    "gmail-address": "",
    "Junk-Removal-Type": "",
    "Junk-Removal-Amount": "",
    "Junk-Removal-Stairs": "",
    "large-furniture-items": "",
    "load-items": "",
    "unload-items": "",
    "Move-Distance":[]
  });

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);


    const emailData = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      emailData.append(key, value);
    });
  
    const hiddenForm = document.createElement('form');
    hiddenForm.style.display = 'none';
  
    Object.entries(formData).forEach(([key, value]) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value;
      hiddenForm.appendChild(input);
    });
  
    document.body.appendChild(hiddenForm);
    emailjs.sendForm('service_zapbe8d', 'template_sbm04bo', hiddenForm, 'BeGkhvlaD0-zU6QFY')
      .then((result) => {

        setFormData({
          "full-name": "",
          "phone-number": "",
          "gmail-address": "",
          "Junk-Removal-Type": "",
          "Junk-Removal-Amount": "",
          "Junk-Removal-Stairs": "",
        });
        } )
           .catch((error) => {
      console.log(error.text);
    })
    .finally(() => {
      setStep(1);
    });
    props.cancel(); 

   };
   const handleRadioChange = (group, event) => {
    setFormData({
      ...formData,
      [group]: event.target.value,
    });
  };
  const handleInputChange = (field, event) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };
    const { open } = props;
    const [step, setStep] = useState(1);
    function prevStep() {
        setStep(step - 1);
      }
    
      function nextStep() {
        setStep(step + 1);
      }
 
      const isLastStep = step === 5;
      const isFirstStep = step === 1;
  

  return (
    <div>
   <React.Fragment>
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>Join Us Now!</DialogTitle>
      <DialogContent>
      {step === 1 && (
              <>
                <TextField
                  label="Full Name"
                  fullWidth
                  margin="normal"
                  value={formData["full-name"]}
                  onChange={(event) => handleInputChange("full-name", event)}
                />
                <TextField
                  label="Phone Number"
                  fullWidth
                  margin="normal"
                  value={formData["phone-number"]}
                  onChange={(event) => handleInputChange("phone-number", event)}
                />
                <TextField
                  label="Email Address"
                  fullWidth
                  margin="normal"
                  value={formData["gmail-address"]}
                  onChange={(event) => handleInputChange("gmail-address", event)}
                />
              </>
            )}
        {step === 2 && (
          <>
          <FormControl>
          <FormLabel id="Junk-Removal-Servecis">What's the main type of junk you need hauled?</FormLabel>
          <RadioGroup
            aria-labelledby="Junk-Removal-Servecis"
            defaultValue={formData["Junk-Removal-Type"]}
            name="Junk-Removal-Type"
            onChange={(event) => handleRadioChange("Junk-Removal-Type", event)}
          >
           <FormControlLabel value="Furniture, appliances or electronics" control={<Radio />} label="Furniture, appliances or electronics" />
           <FormControlLabel value="Mixed trash" control={<Radio />} label="Mixed trash" />
           <FormControlLabel value="Yard waste, construction debris or scrap metal" control={<Radio />} label="Yard waste, construction debris or scrap metal" />
           </RadioGroup>
          </FormControl>

          </>
        )}

        {step === 3 && (
          <>
          
          <FormControl>
          <FormLabel id="Junk-Removal-Servecis">About how much junk is there?</FormLabel>
          <RadioGroup
           aria-labelledby="Junk-Removal-Servecis"
           defaultValue={formData["Junk-Removal-Amount"]}
           name="Junk-Removal-Amount"
           onChange={(event) => handleRadioChange("Junk-Removal-Amount", event)}
          >
           <FormControlLabel value="Enough to fill half of a pickup truck" control={<Radio />} label="Enough to fill half of a pickup truck" />
           <FormControlLabel value="Enough to fill one pickup truck" control={<Radio />} label="Enough to fill one pickup truck" />
           <FormControlLabel value="Enough to fill two pickup tracks" control={<Radio />} label="Enough to fill two pickup tracks" />
           <FormControlLabel value="Enough to fill three pickup trucks" control={<Radio />} label="Enough to fill three pickup trucks" />
           <FormControlLabel value="Enough to fill four or more pickup trucks" control={<Radio />} label="Enough to fill four or more pickup trucks" />

          
           </RadioGroup>
          </FormControl>
          </>
        )}

        {step === 4 && (
          <>
          
          <FormControl>
          <FormLabel id="Junk-Removal-Servecis">How many flights of stairs are there?</FormLabel>
          <RadioGroup
           aria-labelledby="Junk-Removal-Servecis"
           defaultValue={formData["Junk-Removal-Stairs"]}
           name="Junk-Removal-Stairs"
           onChange={(event) => handleRadioChange("Junk-Removal-Stairs", event)}
          >
           <FormControlLabel value="There are no flights of stairs" control={<Radio />} label="There are no flights of stairs" />
           <FormControlLabel value="One flights" control={<Radio />} label="One flights" />
           <FormControlLabel value="Two flights" control={<Radio />} label="Two flights" />
           <FormControlLabel value="Three flights" control={<Radio />} label="Three flights" />
           <FormControlLabel value="Elevator available" control={<Radio />} label="Elevator available" />

           </RadioGroup>
          </FormControl>
          </>
        )}

        {step === 5 && (
          <>
            <DialogContentText>Confirmation</DialogContentText>
            <p>
      <strong>Full Name:</strong> {formData["full-name"]}
    </p>
    <p>
      <strong>Phone Number:</strong> {formData["phone-number"]}
    </p>
    <p>
      <strong>Email Address:</strong> {formData["gmail-address"]}
    </p>
            <strong>Junk Removal Type:</strong> {formData["Junk-Removal-Type"]} <br />
            <strong>Junk Removal Amount:</strong> {formData["Junk-Removal-Amount"]} <br />
            <strong>Junk Removal Stairs:</strong> {formData["Junk-Removal-Stairs"]} <br />


          </>
        )}
      </DialogContent>
      <DialogActions>
       <center>
       {isFirstStep ?          
        <Button onClick={() => { props.cancel(); setStep(1); setFormData({ "full-name": "", "phone-number": "", "gmail-address": "", "Junk-Removal-Type": "", "Junk-Removal-Amount": "", "Junk-Removal-Stairs": "" }); }}>
              Cancel
               </Button>
              :
              <Button onClick={prevStep}>
                Back
             </Button>
                }

            {isLastStep ?          
            <Button variant="contained" onClick={sendEmail}>Submit</Button>
              :
              <Button onClick={nextStep}>
              Next
            </Button>
                }
       
        </center> 
      </DialogActions>
    </Dialog>
  </React.Fragment>
    </div>
  )
}


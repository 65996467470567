import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControlLabel, FormControl, FormLabel, Radio, RadioGroup, Slider,TextField } from '@mui/material';
import emailjs from '@emailjs/browser';


export default function FurnitureDeliveryPopUp(props) {
    const { open } = props;
    const [step, setStep] = useState(1);
  
    function prevStep() {
      setStep(step - 1);
    }
  
    function nextStep() {
      setStep(step + 1);
    }
    const [value, setValue] = useState([10,20]); 
    const isLastStep = step === 6;
    const isFirstStep = step === 1;

    const handleChange  = (event, newValue) => {
      setValue(newValue);
    };
    const valuetext = (value) => {
      return `${value}`;
    }; 

    const [formData, setFormData] = useState({
      "full-name": "",
      "phone-number": "",
      "gmail-address": "",



      "Junk-Removal-Type": "",
      "Junk-Removal-Amount": "",
      "Junk-Removal-Stairs": "",
  
  
      "Move-Date": "",
      "Move-Size": "",
      "Loading-Setup": "",
      "Unloading-Setup": "",


      "large-furniture-items": "",
      "load-items": "",
      "unload-items": "",
      "Move-Distance":value
    });
    const handleInputChange = (field, event) => {
      setFormData({
        ...formData,
        [field]: event.target.value,
      });
    };


    const sendEmail = (e) => {
      e.preventDefault();
      console.log("Form Data:", formData);
  
  
      const emailData = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        emailData.append(key, value);
      });
    
      const hiddenForm = document.createElement('form');
      hiddenForm.style.display = 'none';
    
      Object.entries(formData).forEach(([key, value]) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        hiddenForm.appendChild(input);
      });
    
      document.body.appendChild(hiddenForm);
      emailjs.sendForm('service_zapbe8d', 'template_sbm04bo', hiddenForm, 'BeGkhvlaD0-zU6QFY')
      .then((result) => {
          console.log("Email Sent:", result);
          setFormData({
            "full-name": "",
            "phone-number": "",
            "gmail-address": "",
        
            "Move-Date": "",
            "Move-Size": "",
            "Loading-Setup": "",
            "Unloading-Setup": "",

            "large-furniture-items": "",
            "load-items": "",
            "unload-items": "",
            "Move-Distance":value             });
        })
        .catch((error) => {
          console.log("Email Error:", error.text);
        })
        .finally(() => {
          setStep(1);
        });
    
      props.cancel();
    };
    

    
  return (
    <React.Fragment>
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>Join Us Now!</DialogTitle>
      <DialogContent>
      {step === 1 && (
              <>
                <TextField
                  label="Full Name"
                  fullWidth
                  margin="normal"
                  value={formData["full-name"]}
                  onChange={(event) => handleInputChange("full-name", event)}
                />
                <TextField
                  label="Phone Number"
                  fullWidth
                  margin="normal"
                  value={formData["phone-number"]}
                  onChange={(event) => handleInputChange("phone-number", event)}
                />
                <TextField
                  label="Email Adress"
                  fullWidth
                  margin="normal"
                  value={formData["gmail-address"]}
                  onChange={(event) => handleInputChange("gmail-address", event)}
                />
              </>
            )}
        {step === 2 && (
          <>
          <FormControl>
          <FormLabel id="Furniture-Delivery-Apply">How many large furniture items(longer than 4 feet)need to be moved?</FormLabel>

          <RadioGroup
           aria-labelledby="Furniture-Delivery-Apply"          
           name="large-furniture-items"
           value={formData["large-furniture-items"]}
           onChange={(event) => handleInputChange("large-furniture-items", event)}

           >
           <FormControlLabel value="1-2 items" control={<Radio />} label="1-2 items" />
           <FormControlLabel value="3-5 items" control={<Radio />} label="3-5 items" />
           <FormControlLabel value="6-10 items" control={<Radio />} label="6-10 items" />
           <FormControlLabel value="11-20 items" control={<Radio />} label="11-20 items" />
           </RadioGroup>
          </FormControl>

          </>
        )}

        {step === 3 && (
          <>
          
          <FormControl>
          <FormLabel id="Furniture-Delivery-Apply">How will the mover load items at the starting location?</FormLabel>
          <RadioGroup
           aria-labelledby="Furniture-Delivery-Apply"
          name="load-items"
          value={formData["load-items"]}
          onChange={(event) => handleInputChange("load-items", event)}

          >
           <FormControlLabel value="Stairs to load items" control={<Radio />} label="Stairs to load items" />
           <FormControlLabel value="Passenger elevator to load items" control={<Radio />} label="Passenger elevator to load items" />
           <FormControlLabel value="Freight elevator to load items" control={<Radio />} label="Freight elevator to load items" />
           <FormControlLabel value="No change in floors to load items" control={<Radio />} label="No change in floors to load items" />
          
           </RadioGroup>
          </FormControl>
          </>
        )}

        {step === 4 && (
          <>
          
          <FormControl>
          <FormLabel id="Furniture-Delivery-Apply">How will the mover unload items at the ending location?</FormLabel>
          <RadioGroup
           aria-labelledby="Furniture-Delivery-Apply"
           name="unload-items"
           value={formData["unload-items"]}
           onChange={(event) => handleInputChange("unload-items", event)}
 
          >
           <FormControlLabel value="Stairs to unload items" control={<Radio />} label="Stairs to unload items" />
           <FormControlLabel value="Passenger elevator to unload items" control={<Radio />} label="Passenger elevator to unload items" />
           <FormControlLabel value="Freight elevator to unload items" control={<Radio />} label="Freight elevator to unload items" />
           <FormControlLabel value="No change in floors to unload items" control={<Radio />} label="No change in floors to unload items" />
          
           </RadioGroup>
          </FormControl>
          </>
        )}
          {step === 5 && (
          <>
          <p>How far are you moving?(miles)</p>
          <Slider
            getAriaLabel={() => 'Distance'}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            />
            
          </>
        )}
         

        {step === 6 && (
          <>
            <DialogContentText>Confirmation</DialogContentText>
            <p><strong>Full Name:</strong> {formData["full-name"]}</p>
            <p><strong>Phone Number:</strong> {formData["phone-number"]}</p>
            <p><strong>Email Address:</strong> {formData["gmail-address"]}</p>
            <p><strong>Large Furniture Items:</strong> {formData["large-furniture-items"]}</p>
            <p><strong>Loading Setup:</strong> {formData["load-items"]}</p>
            <p><strong>Unloading Setup:</strong> {formData["unload-items"]}</p>
            <p><strong>Move Distance:</strong> {value[0]} - {value[1]} miles</p>
          </>
        )}

      </DialogContent>
      <DialogActions>
       <center>
       {isFirstStep ?          
                <Button onClick={() => {props.cancel(); setStep(1)}}>
                Cancel
              </Button>
              :
              <Button onClick={prevStep}>
                Back
             </Button>
                }

            {isLastStep ?          
            <Button variant="contained" onClick={sendEmail}>Submit</Button>
              :
              <Button onClick={nextStep}>
              Next
            </Button>
          }
        </center> 
      </DialogActions>
    </Dialog>
  </React.Fragment>
  )
}

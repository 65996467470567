import React from 'react'
import "./footer.css"
function Footer() {
    /*
                        <div class="col-md-6 item text">
                    <h3>Developed by</h3>
                        <a href="https://www.linkedin.com/in/khaled-mansouri-75827319b/">Mansouri khaled</a>
                    </div>
                    <p className='copyright'>Local Movers © 2024</p>  
    */
  return (
    <div className="footer-dark"> 
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-3 item">
                        <h3>Services</h3>
                        <ul>
                            <li><a href="#Our-Services">Full-Service Moving</a></li>
                            <li><a href="#Our-Services">Furniture Delivery</a></li>
                            <li><a href="#Our-Services">Junk Removal</a></li>
 
                        </ul>
                    </div>
                    <div className="col-sm-6 col-md-3 item">
                        <h3>Services</h3>
                        <ul>
                            <li><a href="#About-Us">Transition Experts</a></li>
                            <li><a href="#About-Us">Personalized Solutions</a></li>
                            <li><a href="#About-Us">Reliable Companions</a></li>
                        </ul>
                    </div>
                    <div className="col-md-6 item text">
                        <h3>Local Movers</h3>
                        <p>Seamless transitions made stress-free. Experience precision, 
                        care, and personalized solutions for your move with our dedicated team.</p>
                    </div>

                </div>
           
                <div className="row">
            <div className="col-md-12 item text">
              <p className='copyright'>© {new Date().getFullYear()} Local Movers. All Rights Reserved.</p>
            </div>
          </div>
            </div>
        </footer>
    </div>
  )
}

export default Footer
import React, { useState } from 'react'
import "./services.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Popup from '../Apply/MovingPopUp';
import FurnitureDeliveryPopUp from '../Apply/FurnitureDeliveryPopUp';
import JunkRemovalPopup from '../Apply/JunkRemovalPopup';
import full_Services_moving from '../../Images movers/Full services moving.png'
import FurnitureDelivery from '../../Images movers/Furniture-delivery.png'
import JunkRemoval from '../../Images movers/Junk removal.png'


function Services() {
  const[Open,SetOpen]=useState(false)
  const[OpenFD,SetOpenFD]=useState(false)
  const[OpenJR,SetOpenJR]=useState(false)

  /*pagination={{
  clickable: true }}*/

function cancel(){
  SetOpen(false)
}

function Close(){
  SetOpenFD(false)
}
function CloseJR(){
  SetOpenJR(false)
}

  return (
<section id="Our-Services">


   <div className='toptitre'>
   
    <div className='Our-Services'>
    <h1 id="Our-Services" > Our Services</h1>

      <div className='Servecis-Slide-Container'>

     

      <Swiper
    spaceBetween={50}
    slidesPerView={2}
    pagination={{ clickable: true }}
    loop={true}
    modules={[Navigation, Scrollbar, A11y]}
    navigation
    breakpoints={{
        0: {
            slidesPerView: 1,
        },
        520: {
            slidesPerView: 2,
        },
        930: {
            slidesPerView: 2,
        },
    }}
            >
  
  <SwiperSlide >
     
        <div className='Slide-content'>
          <div className='Card-servecis'>
            <div className='cards'>
              <div className='Pic-services'>
                <span className='overlay'></span>
                  <div className='cads-our-picture'>
                    <img src={full_Services_moving} alt="Our-services" className='cardIm'/>
                  </div>
              </div>
              <div className='servecis-content'>
                <h2 className='title-servecis'>Full-Services Moving</h2>
                  <p className='Description-servecis'>Transition smoothly with our full-service moving solutions. Our skilled team manages every step, from packing and loading to transportation, unloading, and unpacking at your destination.
                   We prioritize the safety of your belongings, ensuring a stress-free move.</p>
                  <button className='button' onClick={()=>{SetOpen(true)}}>
                  Book Now
                </button>
              </div>
            </div>           
          </div>
        </div>
        </SwiperSlide>

        <SwiperSlide >
     
     <div className='Slide-content'>
       <div className='Card-servecis'>
         <div className='cards'>
           <div className='Pic-services'>
             <span className='overlay'></span>
               <div className='cads-our-picture'>
                 <img src={FurnitureDelivery} alt="Our-services" className='cardIm'/>
               </div>
           </div>
           <div className='servecis-content'>
             <h2 className='title-servecis'>Furniture <br/> Delivery</h2>
               <p className='Description-servecis'>Effortlessly receive your furniture items with our reliable delivery service.
                Whether it's a single piece or an entire set, our team ensures prompt and secure delivery to your specified location. We handle your furniture with care, ensuring it reaches you in excellent condition.</p>
               <button className='button' onClick={()=>{SetOpenFD(true)}}>
               Book Now
             </button>
           </div>
         </div>           
       </div>
     </div>
     </SwiperSlide>


     <SwiperSlide >
     
     <div className='Slide-content'>
       <div className='Card-servecis'>
         <div className='cards'>
           <div className='Pic-services'>
             <span className='overlay'></span>
               <div className='cads-our-picture'>
                 <img src={JunkRemoval} alt="Our-services" className='cardIm'/>
               </div>
           </div>
           <div className='servecis-content'>
             <h2 className='title-servecis'>Junk<br/>removal</h2>
               <p className='Description-servecis'>Effortlessly reclaim space with our efficient junk removal service. Let us handle unwanted items responsibly, ensuring clutter-free premises. Whether post-move cleanup or routine decluttering, 
               we expertly manage junk removal, leaving your space streamlined.</p>
               <button className='button' onClick={()=>{SetOpenJR(true)}}>
               Book Now
             </button>
           </div>
         </div>           
       </div>
     </div>
     </SwiperSlide>
        
        
        </Swiper>
    </div>
    </div>
    <Popup
    open={Open}
    cancel={cancel}
    />
    <FurnitureDeliveryPopUp
        open={OpenFD}
        cancel={Close}
    />
    <JunkRemovalPopup
     open={OpenJR}
     cancel={CloseJR}

    />


    </div>
   
    </section>
  )
}

export default Services
import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Header from '../components/header/Header'
import Services from '../components/services/Services'
import AboutUs from '../components/About Us/AboutUs'

import Footer from '../components/footer/Footer'
import Contact from '../components/Contact/Contact'
import Review from '../components/Review/Review'

function HomePage() {

  return (
    <div>
        <Navbar/>
        <Header/>
        <Services/>
        <AboutUs/>
        <Review/>
        <Contact/>
        <Footer/>

    </div>
  )
}

export default HomePage